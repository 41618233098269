//$primary: #355EFC;
/*
$primary: #017eaf;
$secondary: #E93C05;
$tertiary: #555555;
$light: #DFE4FD;
$dark: #011A41;
*/

$primary: #003399;
$secondary: #e93c05;
$tertiary: #555555;
$light: #dfe4fd;
$dark: #0d1e49;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

$font-family-base: 'Roboto', sans-serif;
$headings-font-family: "Jost", sans-serif;
//$font-family-base: "conthraxsemibold", sans-serif;
//$headings-font-family: 'revolvingdorregular', sans-serif;

$body-color: $tertiary;

$headings-color: $dark;

$headings-font-weight: 700;

$display-font-weight: 700;

$enable-responsive-font-sizes: true;

$border-color: $light;

$input-border-color: $light;

$border-radius: 8px;

$border-radius-sm: $border-radius;

$border-radius-lg: $border-radius;

$link-decoration: none;

$enable-negative-margins: true;


@import "~bootstrap/scss/bootstrap";
