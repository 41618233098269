@font-face {
    font-family: 'revolvingdorregular';
    src: url('revolvingdor-webfont.woff2') format('woff2'),
         url('revolvingdor-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'conthraxsemibold';
    src: url('conthrax-sb-webfont.woff2') format('woff2'),
         url('conthrax-sb-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}